import { Languages } from '@livv/models/languages';
import { normalize } from '@livv/utils/helpers';
import {
    enUS as datePickerLocaleEn,
    frFR as datePickerLocaleFr,
    PickersLocaleText,
} from '@mui/x-date-pickers/locales';
import {
    endOfYear as endOfYearDateFns,
    format,
    formatDuration,
    fromUnixTime,
    intervalToDuration,
    startOfYear as startOfYearDateFns,
    // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB, fr } from 'date-fns/locale';
import { Timestamp as ClientTimestamp } from 'firebase/firestore';
import { Timestamp as AdminTimestamp } from 'firebase-admin/firestore';

export const DAY_SECONDS = 86400;

// date of the oldest law in the database March 15th, 1803
export const REFINE_MIN_TIMESTAMP = -5263747200;

export const NOW_IN_SECONDS = Math.round(Date.now() / 1000);

const LITERAL_DATE_FORMAT = { en: 'MMMM d, yyyy', fr: 'd MMMM yyyy' };

export const NUMERIC_DATE_FORMAT = {
    en: 'MM/dd/yyyy',
    fr: 'dd/MM/yyyy',
};

export type Lang = { lang: 'en' | 'fr' };

export const localeMap = {
    en: enGB,
    fr,
};

export const localeDatePickerText: Record<Languages, Partial<PickersLocaleText<Date>>> = {
    en: datePickerLocaleEn.components.MuiLocalizationProvider.defaultProps.localeText,
    fr: datePickerLocaleFr.components.MuiLocalizationProvider.defaultProps.localeText,
};

const MONTHS = [
    'jan',
    'fev',
    'mar',
    'avr',
    'mai',
    'juin',
    'juil',
    'aou',
    'sep',
    'oct',
    'nov',
    'dec',
];

const DATE_LITERAL_REGEX = new RegExp(
    `(\\d{1,2})e?r?\\s(${MONTHS.join('|')})\\w*\\b\\.?\\s(\\d{4})`,
    'i',
);

export const parseDate = (text: string): Date | null => {
    const normalizedText = normalize(text);
    const match = normalizedText.match(DATE_LITERAL_REGEX);
    if (match?.length === 4) {
        const [day, monthString, year] = match.slice(1);
        try {
            return new Date(
                parseInt(year, 10),
                MONTHS.indexOf(monthString.toLowerCase()),
                parseInt(day, 10),
                12,
                0,
            );
        } catch (err) {
            console.error(`unable to parse date from ${match}, ${err}`);

            return null;
        }
    }

    return null;
};

const formatDate = (date: Date, expectedFormat: string, lang: Languages): string => {
    return format(date, expectedFormat, { locale: lang === Languages.fr ? fr : undefined });
};

export const formatDateFromUnixTime = (unixTime: number, lang: string): string =>
    fromUnixTime(unixTime).toLocaleDateString(lang as Languages, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

export const getDate = (date: Date, lang: Languages, numericFormat: boolean = false): string => {
    return formatDate(
        date,
        numericFormat
            ? NUMERIC_DATE_FORMAT[lang || Languages.fr]
            : LITERAL_DATE_FORMAT[lang || Languages.fr],
        lang,
    );
};

/**
 * Format the duration of a date to the closet unit from days to seconds.
 * We cannot use date-fns intervalToDuration then format it to the highest value
 */
export const getFormattedDurationInDayFromInterval = (
    interval: Interval,
    lang: string,
    upperBound = false,
): string => {
    const duration = intervalToDuration(interval);
    if (upperBound) {
        Object.entries(duration).forEach(([key, value]) => {
            if (value) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (duration as any)[key] = value + 1;
            }
        });
    }

    return formatDuration(duration, {
        delimiter: ',',
        locale: lang === Languages.fr ? fr : enGB,
        zero: false,
    }).split(',')[0];
};

export const timestampToDate = (timestamp: number): Date => new Date(timestamp * 1000);
export const dateToTimestamp = (date: Date): number => Math.floor(date.getTime() / 1000);
export const isSameDay = (min: number, max: number) => Math.abs(min - max) <= DAY_SECONDS;

export const getStartAndEndOfYear = (year: number | string): [Date, Date] => {
    const startOfYear = startOfYearDateFns(new Date(`${year}-01-01`));
    const endOfYear = endOfYearDateFns(new Date(`${year}-12-31`));

    return [startOfYear, endOfYear];
};

/** Needed for SSR to properly serialize dates */
export const dbDateToTimestamp = (timestamp: AdminTimestamp): ClientTimestamp =>
    new ClientTimestamp(timestamp.seconds, timestamp.nanoseconds);
